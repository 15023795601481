<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div class="card-content pb-6 mb-3 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'inventory'}"/>
        <div class="container has-text-centered mb-1">
          <div class="pt-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            {{ p(adventureTeam, 'powerup', 2, false) }}
          </div>
        </div>
        <div class="container has-text-centered">
          <div class="is-size-6 has-text-grey mb-5 px-4">
            {{ $store.state.uiMods.powerupSubtext ? $store.state.uiMods.powerupSubtext
            : 'Powerups allow you to add/remove points/time from your own or other teams.'}}
          </div>
          <div v-if="powerups && powerups.length === 0" class="is-size-6 has-text-grey mb-5 px-4 has-text-centered mt-6 pt-6">
            <i class="fas fa-bolt mr-1"></i>
            Your {{ p(adventureTeam, 'powerup', 2) }} will appear here.
          </div>
          <div v-if="powerups && powerups.length > 0">
            <div v-for="(powerup, index) in powerups" :key="index" class="card p-4 mb-2">
              <div class="mb-4 notranslate" v-markdown>{{ powerup.text }}</div>
              <div
              @click="processUseClick(index)"
              v-if="!powerup.isUsed && (powerup.stars || powerup.time || powerup.justSayNo)"
              class="button has-text-weight-semibold is-small is-primary mb-2"
              v-bind:class="{'is-loading': loading}"
              >
                <i class="fas fa-crosshairs mr-2"></i>
                Use
              </div>
              <div v-else-if="(powerup.stars || powerup.time || powerup.justSayNo)" class="has-text-grey-light is-size-7">
                Used
              </div>
            </div>
          </div>
          <div class="container has-text-centered my-5 pb-5">
            <div @click="$router.push({ name: 'Stage' })" class="button is-small is-primary is-rounded">
              <i class="fas fa-clipboard mr-2"></i>
              Back to {{ p(adventureTeam, 'task', 2) }} page
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
    class="modal"
    v-if="selectedPowerup"
    v-bind:class="{'is-active': showPowerupIndex > -1}"
    >
      <div @click="hide()" class="modal-background pointer"></div>
      <div class="modal-card px-3">
        <section v-if="!selectedPowerup.justSayNo" class="modal-card-body has-rounded-corners">
          <div class="container has-text-centered pt-2 mb-3">
            <div class="is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
              Select team...
            </div>
          </div>
          <div class="container has-text-centered pt-2 mb-5">
            <div v-for="(rank, index) in sortedRanking" :key="index">
              <div class="columns is-mobile is-vcentered my-1">
                <div class="column" style="word-wrap: break-word;">
                  {{ rank.teamName }}
                  <span v-if="!adventureTeam.hideLeaderboard">- {{ rank.points }} {{ p(adventureTeam, 'point', rank.points) }}</span>
                  {{ rank.teamName === adventureTeam.teamName ? '(you)' : '' }}
                </div>
                <div class="column is-4">
                  <div
                  @click="usePowerup(selectedPowerup.id, index)"
                  class="button has-text-weight-semibold is-small is-primary"
                  v-bind:class="{'is-loading': loading || selectedPowerup && selectedPowerup.isUsed}"
                  >
                    <i class="fas fa-crosshairs mr-2"></i>
                    Select
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="selectedPowerup.justSayNo" class="modal-card-body has-rounded-corners">
          <div class="container has-text-centered pt-2 mb-3">
            <div class="is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
              Just say no!
            </div>
            <div v-if="adventureTeam.powerupJustSayNo">
              <div class="container has-text-centered pt-2 mb-5">
                Reverse the previous powerup used against your team.<br><br>
                {{ adventureTeam.powerupJustSayNo.stars ? `Regain ${-adventureTeam.powerupJustSayNo.stars} points.` : '' }}
                {{ adventureTeam.powerupJustSayNo.time ? `Regain ${adventureTeam.powerupJustSayNo.time} seconds.` : '' }}
              </div>
              <div
              @click="justSayNo(selectedPowerup.id)"
              class="button has-text-weight-semibold is-small is-primary mb-2"
              v-bind:class="{'is-loading': loading}"
              >
                <i class="fas fa-crosshairs mr-2"></i>
                Use
              </div>
            </div>
            <div v-else class="container has-text-centered pt-2 mb-5">
              You can use your "Just Say No" card if another team uses an attack card on you.
            </div>
          </div>
        </section>
      </div>
      <button @click="hide()" class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import ViewsStat from '@/components/ViewsStat.vue'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
import firebaseApp from '@/firebase/init'
import p from '@/utils/pluralizer'

const db = getFirestore(firebaseApp)

export default {
  name: 'Powerups',
  components: {
    ViewsStat,
    CardNavigation
  },
  data () {
    return {
      loading: false,
      showPowerupIndex: -1,
      leaderboard: null,
      selectedPowerup: null
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    powerups(){
      if(this.adventureTeam){
        return [...this.adventureTeam.powerups].sort((a, b) => {
          if (a.isUsed === b.isUsed) {
            if (a.isUsed) return 1;
            if (a.justSayNo && !b.justSayNo) return -1;
            if (!a.justSayNo && b.justSayNo) return 1;
            if (!a.stars && !a.time && !b.stars && !b.time) return 0;
            if (!a.stars && !a.time) return 1;
            if (!b.stars && !b.time) return -1;
            return -1;
          }
          return a.isUsed ? 1 : -1;
        })
      } else {
        return null
      }
    },
    ranking(){
      if (this.leaderboard && this.leaderboard.ranking) {
        return this.leaderboard.ranking.filter(el => {
          return el.sessionId === this.adventureTeam.sessionId
        })
      } else {
        return []
      }
    },
    sortedRanking() {
      if (this.adventureTeam.hideLeaderboard) {
        const randomizedRanking = this.ranking.filter(rank => rank.teamName !== this.adventureTeam.teamName)
        for (let i = randomizedRanking.length - 1; i > 0; i--) {
          const randomIndex = Math.floor(Math.random() * (i + 1));
          [randomizedRanking[i], randomizedRanking[randomIndex]] = [randomizedRanking[randomIndex], randomizedRanking[i]];
        }
        randomizedRanking.push(this.ranking.find(rank => rank.teamName === this.adventureTeam.teamName));
        return randomizedRanking;
      } else {
        return this.ranking;
      }
    }
  },
  methods: {
    hide(){
      this.showPowerupIndex = -1
      this.selectedPowerup = null
    },
    processUseClick(index) {
      this.showPowerupIndex = index
      const selectedPowerup = this.powerups[index]
      if (selectedPowerup && (selectedPowerup.stars > 0 || selectedPowerup.time < 0)) {
        const teamIndex = this.ranking.findIndex(item => item.teamName === this.adventureTeam.teamName)
        this.usePowerup(selectedPowerup.id, teamIndex)
      } else {
        this.selectedPowerup = selectedPowerup
      }
    },
    usePowerup(powerupId, index) {
      this.loading = true
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
          methodName: 'use-powerup',
          teamCode: this.adventureTeam.teamCode,
          targetTeamName: this.ranking[index].teamName,
          powerupId
      }).then(result => {
          this.loading = false
          this.showPowerupIndex = -1
          this.selectedPowerup = null
          this.$router.push({ name: 'Chat' })
      }).catch(() => {
          this.loading = false
          this.showPowerupIndex = -1
          this.selectedPowerup = null
      })
    },
    justSayNo(powerupId){
      this.loading = true
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'use-just-say-no',
        teamCode: this.adventureTeam.teamCode,
        powerupId
      }).finally(() => {
        this.loading = false
        this.showPowerupIndex = -1
        this.selectedPowerup = null
      })
    },
    showPhotoViewer(event){
      this.$store.commit('showPhotoViewer', event.target.dataset.url)
    },
    onScroll() {
      this.$store.commit('updateScrollPositions', {
        pageName: this.$route.name,
        scrollPosition: window.top.scrollY
      })
    },
    async fetchLeaderboard(adventureId) {
      const leaderboardDoc = await getDoc(doc(db, 'leaderboards', adventureId))
      if (leaderboardDoc.exists()) {
        this.leaderboard = leaderboardDoc.data()
      }
    }
  },
  watch: {
    adventureTeam: {
      immediate: true,
      handler(adventureTeam) {
        if(adventureTeam){
          this.fetchLeaderboard(adventureTeam.adventureId)
        }
      }
    }
  },
  updated() {
    document.querySelectorAll('img').forEach(item => {
      item.removeEventListener('click', this.showPhotoViewer)
      item.addEventListener('click', this.showPhotoViewer)
    })
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)

    document.querySelectorAll('img').forEach(item => {
      item.removeEventListener('click', this.showPhotoViewer)
      item.addEventListener('click', this.showPhotoViewer)
    })

    this.$store.dispatch('enterPage', {
      pageName: 'powerups'
    })

    this.$store.commit('updateShowPowerupAlert', false)
  },
  beforeDestroy() {
    this.$store.commit('updateShowPowerupAlert', false)
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
